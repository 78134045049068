<template>
  <div class="container">
    <div class="title">选择企业</div>
    <div class="desc">请选择一家企业进入团检</div>
    <ul class="list">
      <li
        class="item"
        :class="{ 'item-active': index === currentIndex }"
        v-for="(item, index) in list"
        :key="index"
        @click="onChange(index)"
      >
        {{ item.enterpriseName }}
      </li>
    </ul>
    <div class="botton-wrap">
      <van-button type="primary" round block @click="onSubmit"
        >进入团检</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    list() {
      return JSON.parse(localStorage.getItem("groupList")) || [];
    },
  },
  mounted() {
    this.$setTitle("选择企业");
    this.$store.commit("setTitle", "选择企业");
  },
  methods: {
    onChange(index) {
      this.currentIndex = index;
    },
    onSubmit() {
      const item =  this.list[this.currentIndex]
      this.$store.commit("setToken", item.token);
      this.$store.commit("setMobile", item.phone);
      this.$store.commit("setEnterpriseCode", item.enterpriseCode);
      this.$router.replace(
        "/group-profile?hospitalId=" + this.$store.state.hospitalId
      );
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0 30px;
  background-color: #fff;
}
.title {
  margin-top: 40px;
  font-size: 24px;
  font-weight: bold;
  color: #2c2f37;
}
.desc {
  margin-top: 5px;
  font-size: 15px;
  color: #878d99;
}
.list {
  margin-top: 20px;
}

.item {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 15px;
  color: #2c2f37;
  background-color: #f4f4f4;
  border-radius: 10px;
}

.item-active {
  background-color: #00c4b3;
  color: #fff;
}
.botton-wrap {
  margin-top: 50px;
  padding: 0 18px;
}
</style>